<script>
import Alert from "@/components/global/Alert"
export default {
  name: "CalibrationResultsTable",
  components: {
    Alert,
  },
  props: {
    value:            { type: Object, required: true },
    forceLabel:       { type: Boolean, default: false },
  },
  data() {
    return {
      validCount: 0,
      fields: [
        { key: "variable", label: "Variable", sortable: false },
        { key: "type", label: "Type", sortable: false },
        { key: "shortDescription", label: "Short Description", sortable: false },
      ],
      items: [
        { variable: "", type: "int", shortDescription: "" },
      ],
      options: [
        { value: "int", text: "Integer" },
        { value: "float", text: "Float" },
        { value: "list", text: "List" },
        { value: "table", text: "Table" },
        { value: "string", text: "String" },
        { value: "pseudo code", text: "Pseudo Code" },
      ],
      defaultResult: { variable: "", type: "int", shortDescription: "" },
    }
  },
  computed: {
    errors() {
      return this.value.errors
    },
    alert() {
      let message = "Please correct the following errors:<br /><ul>"
      this.errors.forEach(err => {
        message += `<li>${err}</li>`
      })
      message += "</ul>"
      return {
        message,
        html: true,
        variant: "danger",
      }
    },
    hasErrors() { 
      return this.errors.length > 0
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false 
    },
  },
  watch: {
    items: {
      handler(data) {
        this.value.value = data
      },
      deep: true,
    },
  },
  created() {
    setTimeout(() => {
      if (this.value.value.length > 0) {
        this.items = this.value.value
      }
    }, 1000)
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
    addRow() {
      this.items.push(Object.assign({}, this.defaultResult))
    },
    removeRow(data) {
      this.items.splice(data.index, 1)
    },
    resetRow(data) {
      this.items.splice(data.index, 1, Object.assign({}, this.defaultResult))
    },
  },
}
</script>

<template>
  <div id="calibrationResultsTable" class="calibrationResultsTable">
    <b-table head-variant="dark" :items="items">
      <template #cell(variable)="data">
        <b-form-input v-model="data.item.variable" size="sm" trim :readonly="readOnly"/>
      </template>
      <template #cell(type)="data">
        <b-form-select v-model="data.item.type"  size="sm" :options="options" :disabled="readOnly"/>
      </template>
      <template #cell(shortDescription)="data">
        <b-form-input v-model="data.item.shortDescription"  size="sm" trim :readonly="readOnly" />
        <div class="rowButtons">
          <b-button variant="primary" size="sm" :disabled="readOnly" @click="resetRow(data);">Reset</b-button>
          <b-button v-show="items.length > 1" variant="danger" size="sm" :disabled="readOnly" @click="removeRow(data)">Remove</b-button>
        </div>
      </template>
    </b-table>
    <Alert v-show="hasErrors" v-model="alert" />
    <b-button variant="light" class="additionalBtn" size="sm" :disabled="readOnly" @click="addRow">Add Additional Result</b-button>
  </div>
</template>

<style>
.calibrationResultsTable {
  margin-bottom: 0.5rem;
}
 /************************
**        Table        **
************************/
.calibrationResultsTable table {
  border-collapse:separate;
  border-spacing: 1px 3px;
  border: none;
  margin-bottom: 0px;
}
/************************
**     Table Header    **
************************/
.calibrationResultsTable table thead tr th{
  background-color: #194173 !important;
  color: #fff;
  padding: 2px 3px 2px 10px !important;
  font-size: 0.9em;
}
/* Adjust sort icons due to rounded corners */
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), 
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
}
/************************
**      Table Rows     **
************************/
.calibrationResultsTable table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
  border: none;
}
.calibrationResultsTable table tr td {
  padding: 0px !important;
  /*padding: 3px 12px 3px 15px !important;*/
}
.calibrationResultsTable .rowButtons {
  float: right;
  margin-top: -31px;
  margin-bottom: -2px;
  margin-right: -150px;
  width: 140px;
}
.calibrationResultsTable .rowButtons button {
  margin-right: 3px;
}
.calibrationResultsTable .additionalBtn {
  border: 1px solid #CCCCCC;
  background-color: #FFFFFF;
}
</style>
