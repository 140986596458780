<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"

export default {
  name: "LocationDateTimePicker",
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    value:        { type: Object, required: true },
    forceLabel:     { type: Boolean, default: false },
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "No date selected" 
    },
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    minDate() {
      return this.value.minDate ? this.value.minDate : "" 
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return this.value.state ? this.value.state : null 
    },
    readOnly()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    disabled()    {
      return this.value.disabled ? this.value.disabled : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", this.value)
      },
      deep: true,
    },
  },
  created() {
    var today = new Date()
    var dateTime = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()} 00:00:00`
    this.value.value = dateTime
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div class="dateTimePicker">
    <label
      v-if="forceLabel"
      :for="value.id"
    >
      {{ value.label }}:
    </label>
    <VueCtkDateTimePicker v-model="value.value" :minDate="minDate" :noLabel="true"  label="" />
    <!--<b-form-datepicker 
      v-model="value.value"
      :id="id" 
      :state="state"
      :placeholder="placeholder"
      :readonly="readOnly"
      :disabled="disabled"
      today-button
      reset-button
      close-button
      locale="en"
      :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
      :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
      @input="onChange"
    />-->
    <b-form-invalid-feedback :id="id + '-live-feedback'">{{message}}</b-form-invalid-feedback>
    <b-form-text v-if="tip" :id="id + '-live-help'">{{tip}}</b-form-text>
  </div>
</template>

<style>
.dateTimePicker {
  padding-bottom: 0.5rem;
}
</style>
