<!-- eslint-disable vue/script-indent -->
<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import ResultsContainer from "@/components/global/ResultsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import DeploymentModal from "@/components/asset/DeploymentModal"
import AssetTemplateModal from "@/components/asset/AssetTemplateModal"
import MoveAssetModal from "@/components/asset/MoveAssetModal"
import CollectionsContainer from "@/components/asset/CollectionsContainer"
import FormModal from "@/components/global/FormModal"
import ActivityFeed from "@/components/global/ActivityFeed"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    ResultsContainer,
    DetailsTopBar,
    FormModal,
    IncidentModal,
    DeploymentModal,
    AssetTemplateModal,
    ActivityFeed,
    MoveAssetModal,
    CollectionsContainer,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      armid: 0,
      locationId: 0,
      locationFullName: "",
      selectedDeploymentId: "None",
      collectionArmid: null,
      collectionAssets: [],
      attachmentsTable: "",
      deleteData: "",
      auditDateVal: "",
      fileControlled: false,
      incDisplayName: "CHANGEME",
      icon: <i class="fa fa-trash"></i>,
      assetCategory: "physical",
      nodeData: "",
      comment: "",
      productId: 0,
      vendorId: 0,
      manufacturerId: 0, 
      nodeId: "",
      fieldForTable: "",
      startDateValue: new Date(),
      endDateValue: "",
      assetCollectionType: "",
      buttonVariant: "light",
      allDataExpanded: true,
      activtyExpanded: false,
      fileId: 0,
      canSaveAssetAttachments:false,
      canViewAssetControlledAttachments: false,
      canSaveAssetControlledAttachments: false,
      canViewAssetAttachments: false,
      canEditAssetRecord: false,
      canEditLocationStatus: false,
      canViewControls: false,
      canEditControls: false,
      attachmentKey:"", 
      allData: [],
      editModalRecord: "",
      editModalType: "",
      moveAssetNodeId: "",
      editauditModalValue: {},
      editModalErrorMessage: "",
      parentCollectionType: "",
      assetType: "",
      instrumentClassCodes: [],
      associatedPlans: [],
      associateHumanAssetType: "",
      associateHumanCollectionType: "",
      associateHumanProduct: "",
      associateHumanInstrumentClasses: [],
      associatePlanModalFooterButtons: {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      },
      rescheduleDueDateModalData: {},
      rescheduleDueDateModalFooterButtons: {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      },
      sections: [
        {
          id: "assetInformation",
          title: "Asset Information",
          type: "detailsContainer",
          mapping: [
            { field: "productDetailByProductId.productId", label: "Product", description: "Manufacturer, Product Model, Product Name", cols: 4, type: "pickerwithicon", readOnly: true, template: false}, 
            { field: "assetVendorByVendorId.vendorId", label: "Vendor Name", cols: 4, type: "vendorpickerwithicon", readOnly: true, template: false, description: "Manufacturer, Product Model, Product Name" },
            
            { field: "nickname", label: "Nickname", cols: 3, type: "text", readOnly: false, template: false, 
              message: "Nickname is required",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "active", label: "Active", cols: 1, type: "checkbox", readOnly: false, switch: true },
            { field: "serialId", label: "Serial ID", cols: 2, type: "text", readOnly: false, template: false },

            { field: "assetStatusLocationsByArmid.statusId", label: "Asset Status", cols: 2, type: "assetstatuspicker", readOnly: true },
            { field: "currentLocationId", label: "Asset Location", cols: 3, type: "assetlocationpicker", readOnly: true},
            { field: "startDate", label: "Start Date", minDate: "", maxDate: "", cols: 2, type: "datepicker", readOnly: false, 
              message: "Start Date is required",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "endDate", label: "End Date", minDate: "", maxDate: "", cols: 2, type: "datepicker", readOnly: false },

            
            { field: "latestAuditInfo.auditDate", label: "Last Audit Date", cols: 2, type: "datepicker", readOnly: true, switch: true },
            { field: "latestAuditInfo.personId", label: "Last Audit By", cols:2, type: "peoplepicker", readOnly: true, switch: true },
            { field: "latestAuditInfo.locationId", label: "Last Audit Location", cols: 3, type: "assetlocationpicker", readOnly: true, switch: true },
            { field: "hasLabPropTag", label: "Has Property Tag", cols: 1, type: "checkbox", switch: true},
            { field: "labCode", label: "Current Lab Owner", cols: 2, type: "assetlabpicker", readOnly: false, defaultLabel: "Select One...", 
              message: "Current Lab Owner is required",
              validation: {
                rules: {
                  required: false,
                },
              }, 
            },
            
            { field: "armSteward", label: "Lab Custodian", cols: 2, type: "peoplepicker", readOnly: false, 
              message: "Lab Custodian is required",
              validation:{
                rules: {
                  required: true,
                },
              },
            },
            { field: "labTagLocation", label: "Lab Property Tag Location", cols: 2, type: "text", readOnly: false },

            { field: "labPropertyTag", label: "Lab Property Tag", cols: 2, type: "text", readOnly: false },
            { field: "assetCategoryCode", label: "Asset Category", cols: 2, type: "assetcategorypicker", readOnly: true },
            { field: "collectionTypeCode", label: "Collection Type", cols: 2, type: "collectiontypepicker", readOnly: false },
            { field: "armid", label: "ARMID", cols: 2, type: "text", readOnly: true, template: false },
            { field: "collectionArmid", label: "Parent Asset", cols: 2, type: "assetlink", readOnly: true, template: false },
            { field: "armTrackingCode", label: "ARM Tracking Code", cols: 2, type: "text", readOnly: true, template: false },
            { field: "primaryCustodian", label: "ARM Custodian", cols: 2, type: "peoplepicker", readOnly: false, 
              message: "ARM Custodian is required",
              validation: {
                rules: {
                  required: true,
                },
              }, 
            },

            { field: "assetPurchaseOrderInfoByArmid.acquisitionRefNum", label: "Acquisition Reference", cols: 2, type: "text", readOnly: false, template: false },
            { field: "assetPurchaseOrderInfoByArmid.acquisitionCost", label: "Acquisition Cost", cols: 2, type: "text", prepend: "$", readOnly: false, template: false },
            { field: "capitalPurchase", label: "Capital Purchase", cols: 2, type: "checkbox", readOnly: false, switch: true },
            { field: "description", label: "Configuration", cols: 12, type: "textarea", readOnly: false },

            // Lab of ownership will go here

            // { field: "productDetailByproductId.productControlDetailByproductId.notes", label: "Control Notes", cols: 12, type: "editor", readOnly: true, template: false },

          ],
          rows: [],
          expanded: true,
        },
        {
          id: "collectionConfiguration",
          title: "Collection Configuration",
          type: "collectionsContainer",
          data:  {},
          expanded: false,
        },
        
        {
          id: "locationDetails",
          title: "Location and Status History",
          type: "resultsContainer",
          fields: [
            { key: "eventTimestamp", label: "Event Date", tdClass: "minDateWidth", sortable: false },
            { key: "statusIdReason", label: "Asset Status", sortable: false },
            { key: "locationName", label: "Location", sortable: false },
            { key: "statusChangeDesc", label: "Comment", sortable: false },
            { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
            { key: "lastUpdated.by", label: "Updated By", sortable: false },
          ],
          rows: [],
          headerButtons: [],
          expanded: false,
        },
        {
          id: "deploymentTimeline",
          title: "Upcoming Deployments",
          type: "resultsContainer",
          fields: [
            { key: "siteName", label: "Location", sortable: true },
            { key: "facilityName", label: "Facility", sortable: true },
            { key: "startDate", label: "Start", sortable: true },
            { key: "endDate", label: "End", sortable: true },
            { key: "status", label: "Status", sortable: true },
            { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
          ],
          headerButtons: [],
          rows: [],
          expanded: false,
        },
        {
          id: "calibrationPlans",
          title: "Calibration Plans",
          type: "resultsContainer",
          fields: [
            { key: "planName", label: "Calibration Plan", sortable: true },
            { key: "calibrationTypeDesc", label: "Calibration Type", sortable: true },
            { key: "interval", label: "Interval", sortable: true },
            { key: "dueDate", label: "Due Date", sortable: true },
            { key: "parentPlanId", label: "planId", thClass: "d-none", tdClass: "d-none" },
            { key: "buttons", label: "Actions", sortable: false },
          ],
          headerButtons: [],
          rows: [],
          expanded: false,
        },
        {
          id: "calibrationHistory",
          title: "Calibration History",
          type: "resultsContainer",
          fields: [
            { key: "eventId", label: "eventId", thClass: "d-none", tdClass: "d-none" },
            { key: "planName", label: "Calibration Plan", sortable: true },
            { key: "calibrationType", label: "Calibration Type", sortable: true },
            { key: "performedBy", label: "Performed By", sortable: true },
            { key: "startDate", label: "Calibration Start", sortable: true },
            { key: "endDate", label: "Calibration End", sortable: true },
          ],
          headerButtons: [],
          rows: [],
          expanded: false,
        },
        {
          id: "attachmentDetails",
          title: "Attachments",
          type: "resultsContainer",
          fields: [
            { key: "assetFileByFileId..fileDesc", label: "File Description",  sortable: true },
            { key: "assetFileByFileId..fileMetadata.filename", label: "File Name", value: "assetFileByFileId.nodeId",  sortable: true, value: "assetFileByFileId.base64File"},
            { key: "dbTable", label: "File Association", sortable: false },
            // { key: "assetFileByFileId.createdBy", label: "Stored By", sortable: false },
            { key: "assetFileByFileId.fileControlled", label: "Controlled", sortable: false },
            // { key: "assetFileByFileId.created", label: "Stored On", sortable: false },
            // { key: "fileMetadata.productIds", label: "Stored On", sortable: false },
          ],
          // eslint-disable-next-line camelcase
          rows: [],
          expanded: false,
        },
        {
          id: "commentDetails",
          title: "Comments",
          type: "activityfeed",
          fields: [
            // eslint-disable-next-line no-useless-concat
            { key: "portrait", label: "", class: "thread" },
            { key: "personByPersonId.fullName", label: "", class: "commentName"},
            { key: "comment", name: "personByPersonId.fullName", label: "", value: "" },
            { key: "commentTimestamp", label: "", class: "commentDate", class:"commentDate"},
            // eslint-disable-next-line camelcase
          ],
          rows: [],
          headerButtons: [{id: "commentButton", variant: "light", text: "Add Comment" }],
          expanded: false,
        },
      ],
      collectionsRefresh: 0,
      hasLabPropertyTag: true,
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    editauditModalData() {
      return this.editauditModalValue
    },
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    locHeaderButtons() {
      const items = []

      if (this.canEditLocationStatus) {
        items.push({
          id: "moveAssetButton",
          variant: "light",
          text: "Move to Location",
        })
      }

      return items
    },
    attHeaderButtons() {
      const items = []
      if (this.canSaveAssetAttachments) {
        items.push({
          id: "addAttachmentButton",
          variant: "light",
          text: "Add Attachment",
        })
      }
      

      return items
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      // if (this.editAuditModalRecord != "") {
      //   btns["leftButtons"] = [
      //     {
      //       id: "deleteRecord",
      //       text: "Delete Record",
      //       variant: "danger",
      //     },
      //   ]
      // }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      if (this.canEditAssetRecord) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
        if (!this.isCollection) {
          items.push({
            id: "addAuditButton",
            type: "button",
            label: "Add New Audit",
            position: "left",
            variant: this.buttonVariant,
          })
        }
      }
      if (this.canEditLocationStatus) {
        items.push({
          id: "moveAssetButton",
          type: "button",
          label: "Move to Location",
          position: "left",
          variant: this.buttonVariant,
        })
      }
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      if (this.hasRole("asset.asset") && this.canDelete) {
        items.push({
          id: "deleteRecordButton",
          type: "button",
          label: "Delete Record",
          position: "right",
          variant: "danger",
        })
      }
      
      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: true,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },
    isCollection() {
      return this.assetCategory == "collection"
    },
    canDelete() {
      let canDelete = false
      if (this.isCollection && this.collectionAssets.length == 0) {
        canDelete = true
      } else if (this.assetCategory == "physical") {
        canDelete = true
      }
      return canDelete
    },
    associatePlanModalData() {
      const result = {
        title: "Associate Calibration Plan",
        rows: [
          {
            items: [
              {
                data: {
                  id: "planPicker",
                  label: "Calibration Plan",
                  value: {
                    productType: this.productId,
                    collectionType: this.parentCollectionType,
                    assetType: this.assetType,
                    instrumentClassCodes: this.instrumentClassCodes,
                    exclusions: this.associatedPlans,
                  },
                  readOnly: false,  
                },
                type: "calibrationassociationpicker",
                cols: 12,
              },
              {
                data: {
                  id: "humanAssetType",
                  label: "Asset Type",
                  value: this.associateHumanAssetType,
                  readOnly: true,  
                },
                type: "text",
                cols: 12,
              },
              {
                data: {
                  id: "humanCollectionType",
                  label: "Collection Type",
                  value: this.associateHumanCollectionType,
                  readOnly: true,  
                },
                type: "text",
                cols: 12,
              },
              {
                data: {
                  id: "humanProductType",
                  label: "Product Type",
                  value: this.associateHumanProduct,
                  readOnly: true,  
                },
                type: "text",
                cols: 12,
              },
            ],
          },
        ],
      }

      this.associateHumanInstrumentClasses.forEach((itm,i) => {
        result.rows[0].items.push({
          data: {
            id: `humanInstrumentClass_${i}`,
            label: `Instrument Class (${i + 1})`,
            value: itm,
            readOnly: true,  
          },
          type: "text",
          cols: 12,
        })
      })

      return result
    },
  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Asset ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Asset ID Format"
      } else {
        if (this.armid != parseInt(this.$route.params.params)) {
          this.incDisplayName = this.armid
          this.updateView() 
        }
      }
    },
    sections: {
      handler(data) {
        data.forEach(section => {
          if (section.id == "commentDetails") {
            section.rows.forEach(row => {
              this.comment = row.value
            })
          }
          if (Object.keys(section).indexOf("rows") > -1) {
            section.rows.forEach(row => {
              if (Object.keys(row).indexOf("items") > -1) {
                row.items.forEach(itm => {
                  if ((itm.data.id == "field_collectionTypeCode") && (!this.isCollection)) {
                    itm.type = "",
                    itm.data.label = ""
                    itm.data.value = null
                  }
                  if (itm.data.id == "field_latestAuditInfo.auditDate") {
                    if ((itm.data.value !== "") && (itm.data.value !== null)) {
                      var minDt = new Date(itm.data.value)
                      var newMinDate = new Date(minDt)
                      newMinDate.setDate(newMinDate.getDate())
                      var date = `${newMinDate.getFullYear()}-${newMinDate.getMonth()+1}-${newMinDate.getDate()}`
                      this.auditDateVal = date
                    }
                  }
                  if (itm.data.id == "field_armid") {
                    this.armid = itm.data.value
                  }
                  if (itm.data.id == "field_currentLocationId") {
                    this.locationId = itm.data.value
                  }
                  if (itm.data.id == "field_startDate") {
                    var minDt = new Date(itm.data.value)
                    var newMinDate = new Date(minDt)
                    newMinDate.setDate(newMinDate.getDate() + 2)
                    var date = `${newMinDate.getFullYear()}-${newMinDate.getMonth()+1}-${newMinDate.getDate()}`
                    this.startDateValue = date
                    itm.data.maxDate = this.endDateValue
                  } if (itm.data.id == "field_endDate") {
                    this.endDateValue = itm.data.value
                    itm.data.minDate = this.startDateValue
                  }
                  if (itm.data.id == "field_hasLabPropTag") {
                    this.hasLabPropertyTag = itm.data.value
                  } if (itm.data.label == "Lab Custodian") {
                    itm.data.validation = {
                      rules: {
                        required: this.hasLabPropertyTag,
                      },
                    }
                  }
                  if (itm.data.id == "field_productDetailByProductId.productId") {
                    this.productId = itm.data.value
                    this.productName = "product_control_details"
                    if (this.productId != "") this.retrieveInstrumentClassCodes()
                  }
                  // if (itm.data.id == "field_assetVendorByVendorId.vendorId") {
                  //   this.vendorId = itm.data.value
                  // }
                  if (itm.data.id == "field_assetCategoryCode") {
                    this.assetCategory = itm.data.value
                  }
                  if (itm.data.id == "collectionTypeCode") {
                    this.assetCollectionType = itm.data.value
                  }
                })
              }
            })
          }
        })
      },
      deep: true,
    },
    hasLabPropertyTag(data) {
      if (this.sections !== null) {
        this.sections.forEach(section => {
          if (Object.keys(section).indexOf("rows") > -1) {
            section.rows.forEach(row => {
              if (Object.keys(row).indexOf("items") > -1) {
                row.items.forEach(itm => {
                  if (itm.data.id == "field_labTagLocation") {
                    itm.data.readOnly = !data
                    if (!data) itm.data.value = ""
                  }
                  if (itm.data.id == "field_labPropertyTag") {
                    itm.data.readOnly = !data
                    if (!data) itm.data.value = ""
                  }
                  if (itm.data.id == "field_armSteward") {
                    itm.validation.rules.required = data
                  }
                })
              }
            })
          }
        })
      } 
    },
  },
  created() {
    this.canEditAssetRecord = this.hasRole("asset.asset_save_assets")
    this.canEditLocationStatus = this.hasRole("asset.asset_loc_stat")
    this.canViewControls = this.hasRole("asset.asset_view_export_ctrl")
    this.canEditControls = this.hasRole("asset.asset_save_export_ctrl")
    this.canViewAssetAttachments = this.hasRole("asset.asset_view_attachments")
    this.canSaveAssetControlledAttachments = this.hasRole("asset.asset_save_attachments_controlled")
    this.canViewAssetControlledAttachments = this.hasRole("asset.asset_view_attachments_controlled")
    this.canSaveAssetAttachments = this.hasRole("asset.asset_save_attachments")
    this.sections.forEach(section => {
      if (section.id == "assetInformation") {
        if (this.canViewControls || this.canEditControls) {
          section.mapping.push({ field: "productDetailByProductId.productControlDetailByProductId.itarEccnCode", label: "Export Control Code", cols: 3, type: "controlpicker", readOnly: true, template: false })
          section.mapping.push({ field: "productDetailByProductId.productControlDetailByProductId.assignedBy", label: "Control Assigned By", cols: 3, type: "assetlabpicker", readOnly: true, template: false })
          section.mapping.push({ field: "productDetailByProductId.productControlDetailByProductId.assignedDate", label: "Control Assigned Date", cols: 3, type: "datepicker", readOnly: true, template: false })
          section.mapping.push({ field: "productDetailByProductId.productControlDetailByProductId.sensitivityRating", label: "Sensitivity Rating", cols: 3, type: "text", readOnly: true, template: false })
          section.mapping.push({ field: "productDetailByProductId.productControlDetailByProductId.hazardCode", label: "Hazard Code", cols: 12, type: "hazardpicker", readOnly: true, template: false })
        }
      } else if (section.id == "deploymentTimeline") {
        if (this.canEditAssetRecord) {
          section.headerButtons.push({ id: "newDeployment", variant: this.buttonVariant, text: "New" })
        }
      } else if (section.id == "locationDetails") {
        if (this.canEditLocationStatus) {
          section.headerButtons.push({ id: "moveAssetButton", variant: this.buttonVariant, text: "Move to Location" })
        }
      } else if (section.id == "calibrationPlans") {
        if (this.canEditAssetRecord) {
          section.headerButtons.push({ id: "associatePlan", variant: this.buttonVariant, text: "Associate Calibration Plan" })
        }
      } else if (section.id == "attachmentDetails") {
        if (this.isAdmin) {
          section.fields.push({ key: "assetFileByFileId.fileReviewed", label: "Reviewed", sortable: false })
        }
        section.fields.push({key: "buttons", label: "Options"})
      }
    })
    this.updateView()
  },
  methods: {
    calculateDueDate(interval) {
      const date = new Date()
      // now that interval is only one object
      if (interval.years && interval.years > 0) {
        date.setFullYear(date.getFullYear() + interval.years)
      }
      if (interval.months && interval.months > 0) {
        date.setMonth(date.getMonth() + interval.months)
      }
      if (interval.days && interval.days > 0) {
        date.setDate(date.getDate() + interval.days)
      }
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
    },
    cellButtonClick(payload) {
      if (payload.id == "approveAttachmentBtn") {
        if (this.isAdmin) {
          this.$bvModal.msgBoxConfirm("Please confirm that you want to mark this file as reviewed.", {
            title: "Please confirm request.",
            size: "md",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: false,
          }).then((value) => {
            if (value == true) {
              this.$store.dispatch("asset/approveAssetAttachment", {fileId: payload.value.fileId}).then(() => {
                this.retrieveAttachments()
              })
            }
          })
        }
      } else if (payload.id == "deleteAttachmentBtn") {
        if (this.canSaveAssetAttachments) {
          this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this file.", {
            title: "Please confirm request.",
            size: "md",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: false,
          }).then((value) => {
            if (value == true) {
              this.$store.dispatch("asset/deleteAssetAttachment", {dbRecord: JSON.stringify(payload.value.dbRecord).replaceAll('"', ""), dbTable: payload.value.dbTable, fileId: payload.value.fileId}).then(() => {
                this.retrieveAttachments()
              })
            }
          })
        } else {
          this.$bvModal.msgBoxConfirm("You need permission to delete this file.", {
            title: "Access Denied.",
            okVariant: "danger",
            footerClass: "p-1",
            hideHeaderClose: false,
            centered: false,
          })
        }
      } else if (payload.id == "deletePlanAssociation") {
        if (this.canEditAssetRecord) {
          this.$bvModal.msgBoxConfirm("Please confirm that you want to remove this plan from the asset.", {
            title: "Please confirm request.",
            size: "md",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: false,
          }).then((value) => {
            if (value == true) {
              this.$store.dispatch("calibration/removePlanFromAsset", {planId: payload.value, assetId: this.armid}).then(() => {
                this.retrievePlanData()
              })
            }
          })
        } else {
          this.$bvModal.msgBoxConfirm("You need permission to delete this association.", {
            title: "Access Denied.",
            okVariant: "danger",
            footerClass: "p-1",
            hideHeaderClose: false,
            centered: false,
          })
        }
      } else if (payload.id == "reschedule") {
        this.rescheduleDueDateModalData = {
          title: "Reschedule Plan Due Date",
          parentPlanId: payload.parentPlanId,
          assetId: this.armid,
          rows: [
            {
              items: [
                {
                  data: {
                    id: "dueDate",
                    label: "Due Date",
                    value: payload.dueDate,
                    readOnly: false,  
                  },
                  type: "datepicker",
                  cols: 12,
                },
              ],
            },
          ],
        }

        this.$bvModal.show("rescheduleDueDateModal")
      } else if (payload.id == "enterCalibration") {
        const plan = String(payload.parentPlanId)
        const asset = String(this.armid)
        const calibrationDetailUrl = `${Config.calibrationBaseUrl}${asset}/${plan}`
        window.open(calibrationDetailUrl)
      } else {
        console.log(`[Details.vue cellButtonClick] Unknown ID '${payload.id}':`, payload)
      }
    },
    onFiles(fileList) {
      this.fileList = fileList
    },
    editModalSaveRecord(target, data) {
      this.armid = parseInt(this.$route.params.params)
      if (target == "attachments") {
        const maxFileSize = 10 * 1024 * 1024
        data.fileList = this.fileList
        data.armid = this.armid !== "" && this.armid !== undefined ? this.armid : null
        data.productId = this.productId !== "" && this.productId !== "" && this.productId !== undefined ? this.productId : null
        data.vendorId = this.vendorId !== 0 && this.vendorId !== "" && this.vendorId !== undefined ? this.vendorId : null
        data.manufacturerId = this.manufacturerId !== "" && this.manufacturerId !== undefined ? this.manufacturerId : null
        
        if (data.fileId.size > maxFileSize) {
          this.$bvToast.toast(`File size exceeds the maximum limit of 10MB.`, {
            title: "Add New Attachment",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }
        this.editModalHideRecord()
        this.$store.dispatch("asset/createAssetFile", data).then(result => {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: `Add New Attachment`,
            autoHideDelay: 5000,
            variant: "success",
          })
        }).catch(error => {
          this.$bvToast.toast(`Add New Attachment: ${error}`, {
            title: `Add New Attachment Failed`,
            autoHideDelay: 5000,
            variant: "danger",
          })
        })
      } else if (target == "audit") {
        if (!data.auditDate || !data.personId || !data.auditLocationId) {
          this.$bvToast.toast(`Please fill all required fields.`, {
            title: "Add New Audit",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }
        this.$store.dispatch("asset/createAssetAuditInfo", {
          armid: parseInt(this.$route.params.params),
          auditDate: data.auditDate,
          personId: data.personId,
          auditLocationId: data.auditLocationId,
        }).then(() => {        
          this.$store.dispatch("asset/getAssetById", armid)         
        })
        setTimeout( () => {
          this.updateView()
        },2000)
        this.parseData(data)
        this.editModalHideRecord()
        this.$bvToast.toast(`Your modifications have been saved.`, {
          title: "Add New Audit",
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.$bvToast.toast(`Your modifications have failed.`, {
          title: "Add New Audit",
          autoHideDelay: 5000,
          variant: "danger",
        })
      }
      setTimeout(() => {
        this.retrieveAttachments()
      }, 3000)
    },
    commentButtonClick(e) {
      this.$store.dispatch("asset/createAssetComments", {personId: this.personId, armid: this.armid, comment: e.data}).then((data) => {
        this.$bvToast.toast(`Your modifications have been saved.`, {
          title: "Add New Comment",
          autoHideDelay: 5000,
          variant: "success",
        })
        comments.value = ""
        this.updateView()

      })
    },
    editModalButtonClick(data) {
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          if (this.debug) console.log("[Details.vue editModalButtonClick] row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = item.data.value
          }
        }
      
        this.editModalSaveRecord(this.editModalType, dataset)
      }
    },
    associatePlanModalButtonClick(data) {
      if (data.event.srcElement.id === "cancelButton") {
        this.$bvModal.hide("associatePlanModal")
      } else if (data.event.srcElement.id === "saveButton") {
        const parentPlanId = data.data.rows[0].items[0].data.value

        this.$store
          .dispatch("calibration/getIntervalForPlan", {
            parentPlanId,
          })
          .then((intervalData) => {
            const interval = intervalData.interval
            const dueDate = this.calculateDueDate(interval)
            return this.$store.dispatch("calibration/associatePlanWithAsset", {
              planId: parentPlanId,
              assetId: this.armid,
              dueDate, // due date from above
            })
          })
          .then((result) => {
            if (result) {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Associate Calibration Plan",
                autoHideDelay: 5000,
                variant: "success",
              })
              this.$bvModal.hide("associatePlanModal")
              this.retrievePlanData()
            } else {
              this.$bvToast.toast(`Your modifications have failed.`, {
                title: "Associate Calibration Plan",
                autoHideDelay: 5000,
                variant: "danger",
              })
            }
          })
          .catch((error) => {
            console.error("Error during association:", error)
            this.$bvToast.toast(`An error occurred during the operation.`, {
              title: "Associate Calibration Plan",
              autoHideDelay: 5000,
              variant: "danger",
            })
          })
      } else {
        console.log("Unknown button id for Associate Plan Picker:", data.event.srcElement.id)
      }
    },
    rescheduleDueDateModalButtonClick(data) {
      if (data.event.srcElement.id == "cancelButton") {
        this.$bvModal.hide("rescheduleDueDateModal")
      } else if (data.event.srcElement.id == "saveButton") {
        console.log("rescheduleDueDateModalButtonClick saveButton data:", data.data)
        this.$store.dispatch("calibration/updateAssetCalibrationPlanDueDate", {
          planId: data.data.parentPlanId, 
          assetId: this.armid,
          dueDate: data.data.rows[0].items[0].data.value,
        }).then(result => {
          if (result) {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Update Calibration Plan Due Date",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.$bvModal.hide("rescheduleDueDateModal")
            this.retrievePlanData()
          } else {
            this.$bvToast.toast(`Your modifications have failed.`, {
              title: "Update Calibration Plan Due Date",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        console.log("Unknown button id for Reschedule Due Date Picker:", data.event.srcElement.id)
      }
    },
    editModalHideRecord() {
      if (this.debug) console.log("[Details.vue editModalHideRecord]")
      this.$bvModal.hide("editAuditModal")
      this.editauditModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing ARMID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid ARMID Format"
      } else {
        this.armid = parseInt(this.$route.params.params)
        this.retrieveData()
        this.retrieveComments()
        this.retrieveLocationHistory()
        this.retrieveAttachments()
        this.retrieveDeploymentData()
        this.retrievePlanData()
        this.retrieveCalibrationHistory()
      }
    },
    retrieveData() {      
      this.$store.dispatch("asset/getAssetById", this.armid).then((data) => {
        if (this.debug) console.log("[Details.vue retrieveData] asset/getAssetById data:", data)
        setTimeout(() => {
          if ((typeof(data.assetVendorByVendorId) != "undefined") && (data.assetVendorByVendorId !== null)) {
            this.vendorId = data.assetVendorByVendorId.vendorId
          }
          if ((typeof(data["productDetailByProductId.productAssetTypesByProductId"]) != "undefined") && (data["productDetailByProductId.productAssetTypesByProductId"] !== null)) {
            this.assetType = data["productDetailByProductId.productAssetTypesByProductId"][0]
          }
          if ((typeof(data["assetCollectionInfosByArmid.parentCollectionTypeCode"]) != "undefined") && (data["assetCollectionInfosByArmid.parentCollectionTypeCode"] !== null)) {
            this.parentCollectionType = data["assetCollectionInfosByArmid.parentCollectionTypeCode"]
          }
        })
        this.manufacturerId = data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerId"]
        
     
        this.parseData(data)
      })
    },
    downloadFile(payload) {
      this.$store.dispatch("asset/getFileByFileId", {fileId: payload.fileId}).then((data)=> {
        const byteCharacters = atob(data.allAssetFiles.nodes[0].base64File)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, {
          type:
            payload.assetFileByFileId.fileMetadata.mimetype,
        })
        window.open(URL.createObjectURL(blob), "_blank")
      })
      // this.$bvModal.show(URL.createObjectURL(blob), "_blank")
    },
    retrieveComments() {
      this.$store.dispatch("asset/getAssetComments", {armid: this.armid}).then((data)=> {
        const rows = []
        data.allAssetComments.nodes.forEach(node => {
          rows.push({
            ...node,
          })
          this.nodeId = data
          this.sections.forEach(section => {
            if (section.id == "commentDetails") {
              section.rows = rows
            }
          })
        })
      })
    },
    retrieveAttachments(payload) {
      setTimeout(() => {
        var armID = this.armid !== "" ? this.armid : null
        // eslint-disable-next-line quotes
        var productID = this.productId !== "" && this.productId !== 0 && this.productId !== undefined ? this.productId : null
        var vendorID = this.vendorId !== 0 && this.vendorId !== "" ? this.vendorId : null
        var manufacturerID = this.manufacturerId !== "" && this.manufacturerId !== 0 && this.manufacturerId !== undefined ? this.manufacturerId : null
        this.$store.dispatch("asset/getAssetAttachments",  {armid: armID, productId: productID, vendorId: vendorID, manufacturerId: manufacturerID}).then((data) => {
          const rows = []
          const nodeData = ""
          data.allAssetAttachments.nodes.forEach(node => { 
            this.fileId = node.fileId
            const buttons = [{
              id: "deleteAttachmentBtn",
              variant: "danger",
              label: "Delete",
              value: node,
            }]

            if (this.isAdmin && (node.assetFileByFileId.fileReviewed === false)) {
              buttons.push({
                id: "approveAttachmentBtn",
                variant: "success",
                label: "Approve",
                value: node,
              })
            }

            if (this.canViewAssetAttachments && (node.assetFileByFileId.fileControlled === false)) {
              rows.push({
                ...node,
                ...{
                  buttons,
                },
              })
            } else if (this.canViewAssetAttachments && this.canViewAssetControlledAttachments && (node.assetFileByFileId.fileControlled === true)) {
              rows.push({
                ...node,
                ...{
                  buttons,
                },
              })
            }
          }, 1000)
          this.nodeId = data
          this.sections.forEach(section => {
            if (section.id == "attachmentDetails") {
              if (this.canViewAssetAttachments) {
                section.rows = rows
              }
            }
          })
        })
      }, 3000)
        
    },
    retrieveLocationHistory() {
      this.$store.dispatch("asset/getStatusLocationsByArmid", this.armid).then((data) => {
        this.sections.forEach(section => {
          if (section.id == "locationDetails") {
            section.rows = data
          }
        })
      })
    },
    retrieveDeploymentData() {
      this.$store.dispatch("asset/getDeploymentDataByArmid", this.armid).then((data) => {
        this.sections.forEach(section => {
          if (section.id == "deploymentTimeline") {
            section.rows = data
          }
        })
      })
    },
    // retrievePlanData() {
    //   // TODO: Retrieve calibration plan data
    //   this.$store.dispatch("calibration/getAssetCalibrationPlans", this.armid).then((data) => {
    //     const plans = []
    //     data.forEach(itm => {
    //       plans.push(itm.parentPlanId)
    //     })
    //     this.associatedPlans = plans
    //     this.sections.forEach(section => {
    //       if (section.id == "calibrationPlans") {
    //         section.rows = data
    //       }
    //     })
    //     this.updateDueDateForAssets()
    //   })
    // },
    retrievePlanData() {
      this.$store.dispatch("calibration/getAssetCalibrationPlans", this.armid).then(data => {
        const plans = []
        data.forEach(itm => {
          plans.push(itm.parentPlanId)
        })
        this.associatedPlans = plans
        this.sections.forEach(section => {
          if (section.id === "calibrationPlans") {
            section.rows = data
          }
        })
        // this.updateDueDateForAssets()
      })
    },
    retrieveCalibrationHistory() {
      this.$store.dispatch("calibration/getAssetCalibrationHistory", this.armid).then((data) => {
        this.sections.forEach(section => {
          if (section.id == "calibrationHistory") {
            section.rows = data
          }
        })
      })
    },
    retrieveInstrumentClassCodes() {
      this.$store.dispatch("asset/getInstrumentClassCodes", this.productId).then((data) => {
        this.instrumentClassCodes = data
        this.retrieveHumanFriendlyValues()
      })
    },
    retrieveHumanFriendlyValues() {
      this.$store.dispatch("asset/getHumanReadableAssociationDetails", ({
        collectionType: this.parentCollectionType, productType: this.productId,
        assetType: this.assetType, instrumentClassCodes: this.instrumentClassCodes,
      })).then((data) => {
        this.associateHumanAssetType = data.assetType
        this.associateHumanCollectionType = data.collectionType
        this.associateHumanProduct = data.product
        this.associateHumanInstrumentClasses = data.instrumentClasses
      })
    },
    parseData(data) {
      if (this.debug) console.log("[Details.vue parseData] data:", data)

      if (data.collectionArmid !== null) {
        this.collectionArmid = data.collectionArmid
      } else if (data.collectionTypeCode !== null) {
        this.collectionArmid = this.armid
      }
      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template

              if (mapping.field == "assetCategoryCode") {
                this.assetCategory = data[mapping.field]
              }
              if (mapping.field == "collectionTypeCode") {
                this.assetCollectionType = data[mapping.field]
              }
              if (mapping.field == "armSteward") {
                const hasLabPropertyTag = data["hasLabPropTag"] !== null ? data["hasLabPropTag"] : false
                mapping.validation.rules.required = hasLabPropertyTag
              }
              rows.push({
                data: {
                  id: `field_${mapping.field}`,
                  label: mapping.label,
                  value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                  switch: mapping.switch,
                  prepend: mapping.prepend,
                  readOnly: this.canEditAssetRecord ? mapping.readOnly : true,
                  defaultLabel: mapping.defaultLabel,
                  message: mapping.message,
                  state: mapping.state,
                },
                validation: mapping.validation,
                type: mapping.type,
                cols: mapping.cols,
                template,
              })
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
        if (section.id == "calibrationPlans") {
          section.hide = this.assetCategory != "physical"
        }
      })
    },
    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)

      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if ((id === "addAuditButton") && (!this.isCollection)) {
        var today = new Date()
        var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
        var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
        var dateTime = `${date} ${time}`

        this.editModalRecord = ""
        this.editModalType = "audit"
        this.editauditModalValue = {
          title: "Add New Audit",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "datepicker",
                  data: {
                    id: "auditDate",
                    label: "New Audit Date",
                    value: dateTime,
                    minDate: this.auditDateVal,
                    maxDate: new Date(),
                    readOnly: false,                   
                    color: "silver",
                    state: null,
                    message: "Audit date is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "peoplepicker",
                  externalUsers: true,
                  data: {
                    id: "personId",
                    label: "New Audit By",
                    value: "",
                    state: null,
                    message: "Audited By is required", 
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "locationpicker",
                  data: {
                    switch: true,
                    id: "auditLocationId",
                    label: "New Audit Location",
                    value: false,
                    readOnly: false,
                    state: null,
                    message: "Audit location is required",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editAuditModal")
      }  else if (id == "addAttachmentButton") {
        this.editModalRecord = ""
        this.editModalType = "attachments"
        this.editauditModalValue = {
          title: "Add New Attachment",
          rows: [
            {
              items: [
                {
                  data: {
                    id: "fileId",
                    label: "Attachment Files",
                    defaultLabel: "Use Product Value",
                    readOnly: true,
                    value: null,
                  },
                  type: "fileloader",
                  cols: 12,
                },
                {
                  data: {
                    id: "fileDesc",
                    label: "File Description",
                    readOnly: false,
                    value: "",
                  },
                  type: "text",
                  cols: 12,
                },
                {
                  data: {
                    id: "dbTable",
                    label: "File Association",
                    readOnly: false,
                    value: "",
                    switch: true,
                  },
                  type: "associationpicker",
                  cols: 9,
                  style: "display: flex;, flex-direction: row;",
                },
              ],
            },
          ],
        }
        if (this.canSaveAssetControlledAttachments) {
          this.editauditModalValue.rows[0].items.push({
            data: {
              id: "fileControlled",
              label: "File Controlled",
              readOnly: false,
              value: false,
              switch: true,
            },
            type: "checkbox",
            cols: 3,
            style: "display: flex;, flex-direction: row;",
          })
        }
        this.$bvModal.show("editAuditModal")
      } else if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createTemplateButton") {
        this.$bvModal.show("assetTemplateModal")
      } else if (id == "moveAssetButton") {
        this.moveAssetNodeId = ""
        this.$bvModal.show("MoveAssetModal")
      } else if (id == "deleteRecordButton") {
        this.$bvModal.show("deleteModal")
      } else {
        console.log(`[Details.vue onTopBarButtonClick] id '${id}' not mapped.`)
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.sections)) {
        this.showErrorSummary()
        return
      }

      const data = []
      this.sections.forEach(section => {
        if (Object.keys(section).indexOf("rows") > -1) {
          section.rows.forEach(row => {
            if (Object.keys(row).indexOf("items") > -1) {
              row.items.forEach(item => {
                data[`${item.data.id}`.replace("field_", "")] = item.data.value
              })
            }
          })
        }
      })
      if (this.debug) console.log("[Details.vue saveRecord] Data to Save:", data)

      this.$store.dispatch("asset/updateAssetByArmid", data).then(() => {
        const dat = data
        this.$store.dispatch("asset/updateAssetPurchaseOrderInfoByArmid", dat).then(resp => {
          if (resp) {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Asset Saved",
              autoHideDelay: 5000,
              variant: "success",
            })
          } else {
            this.$bvToast.toast(`Your modifications failed to save.`, {
              title: "Asset Save Failed",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      }).catch(error => {
        if (error.message.includes("duplicate key value violates unique constraint \"assets_product_id_serial_id_idx\"")) {
          this.$bvToast.toast("Serial ID already in use.", {
            title: "Duplicate Serial ID",
            autoHideDelay: 5000,
            variant: "danger",
          })
        } 

        if (error.message.includes('duplicate key value violates unique constraint "lab_property_tag_idx"')) {
          this.$bvToast.toast("Lab Property Tag already in use.", {
            title: "Duplicate Lab Property Tag",
            autoHideDelay: 5000,
            variant: "danger",
          })
        }
      })

      // TODO: Control Overrides
    },
    ResultsContainerButtonClick(data) {
      if (this.debug) console.log("[Details.vue ResultsContainerButtonClick] data:", data)
      if (data.id == "newDeployment") {
        this.selectedDeploymentId = "None"
        this.$bvModal.show("DeploymentModal")
      } else if (data.id == "associatePlan") {
        this.$bvModal.show("associatePlanModal")
      } else {
        this.onTopBarButtonClick(data.id)
      }
    },
    updateChildAssets(data) {
      // Jeremy commented this out as part of AAM-373. Not sure why this would have anything to do with duplicates 
      // but has been this way since 10/31/22. Worth looking into at a later date.
      // if (this.debug) console.log("[Details.vue updateChildAssets] data:", data)
      //    this.collectionAssets = data
    },
    moveAssetRefresh() {
      this.updateView()
      this.refreshCollections()
    },
    refreshCollections() {
      this.collectionsRefresh++
    },
    rowClick(section, data) {
      if (section == "deploymentTimeline") {
        this.selectedDeploymentId = data.nodeId
        this.$bvModal.show("DeploymentModal")
      } else if (section == "attachmentDetails") {
        this.downloadFile(data)
      } else if (section == "locationDetails") {
        this.$bvModal.show("MoveAssetModal")
        this.moveAssetNodeId = data.nodeId
      } else if (section == "calibrationPlans") {
        window.open(`/operationsdash/#/calibration/plan/details/${data.parentPlanId}`, "_blank").focus()
      } else if (section == "calibrationHistory") {
        window.open(`/operationsdash/#/calibration/record/details/${data.eventId}`, "_blank").focus()
      }
    },
    onDeleteRecord() {
      this.$store.dispatch("asset/deleteAssetByArmid", this.armid).then(() => {
        this.$bvToast.toast(`Asset has been deleted.`, {
          title: "Asset Deleted",
          autoHideDelay: 3000,
          variant: "success",
        })
        setTimeout(()=> {
          this.$router.push("/asset/search/")
        }, 3000)
      })
    },
  },
}
</script>

<template>
  <div id="assetDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer 
          v-if="section.type == 'detailsContainer' && !section.hide"
          :key="section.id"
          :title="section.title" 
          :rows="section.rows" 
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
        <ResultsContainer
          v-if="section.type == 'resultsContainer' && !section.hide"
          :key="section.id"
          :title="section.title"
          :rows="section.rows"
          :fields="section.fields"
          :expanded="section.expanded"
          :headerButtons="section.id == 'attachmentDetails' ? attHeaderButtons : section.headerButtons"
          @cellButtonClick="cellButtonClick"
          @buttonClick="ResultsContainerButtonClick"
          @expandToggle="section.expanded = !section.expanded"
          @rowClick="rowClick(section.id, $event)"
        />
        <CollectionsContainer
          v-if="((section.type == 'collectionsContainer') && isCollection)"
          :key="section.id"
          :title="section.title" 
          :value="section.data" 
          :expanded="section.expanded"
          :startDateVal = "startDateValue"
          :collectionType="assetCollectionType"
          :armid="armid"
          :locationId="locationId"
          :locationFullName="locationFullName"
          :triggerRefresh="collectionsRefresh"
          @expandToggle="section.expanded = !section.expanded"
          @updateChildAssets="updateChildAssets"
          @assetAssigned="refreshCollections"
        />
        <ActivityFeed   
          v-if="section.type == 'activityfeed'"
          :key="section.id"
          title="Comments"
          :rows="section.rows"
          :fields="section.fields" 
          :expanded="activtyExpanded" 
          @buttonOnClick="commentButtonClick"
          @expandToggle="activtyExpanded = !activtyExpanded"
        />
      </template>
      
    </div>

    <FormModal id="rescheduleDueDateModal" :value="rescheduleDueDateModalData" :footerButtons="rescheduleDueDateModalFooterButtons" @footerButtonClick="rescheduleDueDateModalButtonClick" />
    <FormModal id="associatePlanModal" :value="associatePlanModalData" :footerButtons="associatePlanModalFooterButtons" @footerButtonClick="associatePlanModalButtonClick" />
    <FormModal id="editAuditModal" :value="editauditModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick" @files="onFiles"  />
    <IncidentModal :group="incidentGroup" subject="Asset Update Request" :displayName="incDisplayName" />
    <AssetTemplateModal type="create" :value="templateData" />
    <MoveAssetModal :armid="armid" :nodeId="moveAssetNodeId" :collectionArmid="collectionArmid" :collectionAssets="collectionAssets" @refresh="moveAssetRefresh" />
    <DeploymentModal :armid="armid" :nodeId="selectedDeploymentId" @refreshDeployments="retrieveDeploymentData" />
    <b-modal id="deleteModal" size="md" title="Delete Record" @ok="onDeleteRecord">
      Are you sure you want to delete this record? This cannot be undone so be absolutely certain.<br /><br />
      <b>Note:</b> This is only available to Administrators.
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="outline-secondary" @click="cancel">
          Cancel
        </b-button>
        <b-button size="sm" variant="danger" @click="ok">
          Delete Record
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
