<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "DQPRTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      dqprInfo: [],
      fields: [
        {
          key: "dqprno",
          label: "RECORD",
          sortable: true,
        },
        {
          key: "dqpr2DqprByDqprno.dqpr2QacodeByQacode.codedesc",
          label: "QA Code",
          sortable: true,
        },
        { 
          key: "latestComment",
          label: "LAST COMMENT",
          sortable: true,
        },
        { 
          key: "formattedEntryDate",
          label: "ISSUE DATE",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.dqprInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateDqprInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getDqprInfo()
  },
  methods: {
    getDqprInfo() {
      const filters = this.personInstruments.map(instr => ({
        site: instr.siteCode,
        facility: instr.facilityCode,
        instrument: instr.instrumentClassCode,
      }))

      this.$store.dispatch("instrument/retrieveDqprs", { filters })
        .then((data) => {
          this.dqprDateFormatter(data)
          this.getComments(data)
          this.dqprInfo = data
        })
        .catch((error) => {
          console.error("Error fetching DQPR Info:", error)
        })
    },
    updateDqprInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map(instr => ({
          site: instr.siteCode,
          facility: instr.facilityCode,
          instrument: instr.instrumentClassCode,
        }))

        this.$store.dispatch("instrument/retrieveDqprs", { filters })
          .then((data) => {
            this.dqprDateFormatter(data)
            this.getComments(data)
            this.dqprInfo = data
          })
          .catch((error) => {
            console.error("Error updating DQPR Info:", error)
          })
      }, 100)
    },
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    dqprDateFormatter(data) {
      data.forEach(item => {
        if (item.dqpr2DqprByDqprno && item.dqpr2DqprByDqprno.entryDate) {
          const entryDateValue = item.dqpr2DqprByDqprno.entryDate
          item.formattedEntryDate = this.formattedDateValue(entryDateValue)
        }
      })
    },
    getComments(data) {
      data.forEach(item => {
        if (item.comments && item.comments.length > 0) {
          const latestComment = item.comments[0] // assuming comments sorted by date, to get "latest" comment
          item.latestComment = latestComment.comment
          item.latestCommentDate = this.formattedDateValue(latestComment.commentDate)
        } 
      })
    },
  },
}
</script>
<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="DQPRs" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">DQPRs</h6>
            <TableGenericList :fields="fields" :records="dqprInfo">
              <template #cell(latestComment)="data">
                <div>
                  {{ data.item.latestComment }}
                  <br/>
                  <small v-if="data.item.latestCommentDate">{{ data.item.latestCommentDate }}</small>
                </div>
              </template>
            </TableGenericList>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
